<script>
import PopUp from '@/components/PopUp.vue'
export default {
    components: { PopUp },
    props: ['availableBookings', 'publicPage', 'selectedTab', 'customTabs', 'bookingItem', 'selectedCustomTab', 'inMenuEditMode','hasChanges','backgroundColor'],
    emits: ['update:hasChanges', 'update:selectedCustomTab','update:selectedTab','update:bookingItem', 'update:inMenuEditMode', 'update:customTabs'],
    created() {    
        
    },
    data() {
        return {  
            tabPrevName: null,
            editNameTab: null
        }
    }, 
    methods:
    {
        selectCustomTab(tab)
        {
            this.$emit('update:selectedCustomTab', tab);          
            this.$emit('update:selectedTab', 'customTab');
        },
        showBooking(bookingItem)
        {   
            this.$emit('update:bookingItem', bookingItem);
            this.$emit('update:selectedTab', 'bokning');          
        },
        selectTab(linkName)
        {
            this.$emit('update:bookingItem', null);
            this.$emit('update:selectedTab', linkName);
        },
        changeToEditMode(mode)
        {
            this.$emit('update:inMenuEditMode', mode);
            
        },
        removeTab(id)
        {
            this.$emit('update:hasChanges', true);       
            var tabs = this.customTabs.filter(x => x.id != id);
            this.$emit('update:customTabs', tabs);       
        },
        addTab(){
            this.editNameTab = { id: 0, name: null };
        },
        addTabToCustomTabs() {
            this.customTabs.push(this.editNameTab);
            this.editNameTab = null;
        },
        changeNameOnCustomTab(tab) {
            this.tabPrevName = tab.name;
            this.editNameTab = tab;
        },
        cancelNameOnCustomTab() {
            this.editNameTab.name = this.tabPrevName;
            this.editNameTab = null;
        }
    }
}
</script>
<template>
   <div class="Menu-menu">                           
        <div class="Menu-edit-menu">
            <button v-if="!inMenuEditMode" class="btn" @click="changeToEditMode(true)" v-tooltip="'Redigera menyn'">
                <i class="fa-solid fa-pen"></i>
            </button>
            <div>
                <button v-if="inMenuEditMode" class="btn" @click="changeToEditMode(false)" v-tooltip="'Stäng redigeringsmenyn'">
                    <i class="fa-solid fa-xmark"></i>
                </button>
                <button v-if="inMenuEditMode && editNameTab == null" class="btn" @click="addTab()">
                    Lägg till Flik
                </button>
            </div>
            
        </div>
        <div class="nav" v-if="!inMenuEditMode">                    
            <div @click="selectTab('hem')" :style="{ 'background-color': selectedTab == 'hem' ? backgroundColor : '' }"><h3> Hem </h3></div>
            <div @click="selectTab('foreningen')" :style="{ 'background-color': selectedTab == 'foreningen'  ? backgroundColor : '' }"><h3>{{this.$store.state.settings.Community.singular}}  </h3> </div>
            <div v-for="tab in customTabs" :style="{ 'background-color': selectedTab == 'customTab' && selectedCustomTab.name == tab.name  ? backgroundColor : '' }">
                <h3 @click="selectCustomTab(tab)"> {{ tab.name }}</h3>
            </div>
            <div v-if="publicPage.include_image_gallery" @click="selectTab('bildgalleriet')" :style="{ 'background-color': selectedTab == 'bildgalleriet' ? backgroundColor : '' }"><h3>{{this.$store.state.settings.ImageGallery.singular}}  </h3> </div>
            <div v-if="availableBookings.length == 1" @click="showBooking(availableBookings[0])" :style="{ 'background-color': selectedTab == 'bokning' ? backgroundColor : '' }">
                <h3>{{ availableBookings[0].name }}</h3>                               
            </div>                 
            <div v-if="availableBookings.length > 1" @click="selectTab('bokning')" :style="{ 'background-color': selectedTab == 'bokning' ? backgroundColor : '' }">              
                <h3>Boka</h3>                             
            </div>                 
            <div v-if="publicPage.include_contact" @click="selectTab('kontakt')" :style="{ 'background-color': selectedTab == 'kontakt'  ? backgroundColor : '' }"><h3>Kontakt</h3></div>                 
        </div>
        <div class="nav" v-else style="background-color: rgba(0,0,0,0.5)">                    
            <div><h3> Hem </h3></div>
            <div><h3>{{this.$store.state.settings.Community.singular}}</h3></div>
            <div v-for="tab in customTabs">
                <h3> {{ tab.name }}</h3>
              
                &nbsp;<i class="fa-solid fa-trash" @click="removeTab(tab.id)" v-tooltip="'Ta bort fliken'"></i>
                &nbsp;<i class="fa-solid fa-pen" @click="changeNameOnCustomTab(tab)" v-tooltip="'Byt namn på fliken'"></i>
            </div>
            <div>
                <h3 :style="{'opacity': publicPage.include_image_gallery ? '1' : '0.4'}">{{this.$store.state.settings.ImageGallery.singular}}</h3> 
                &nbsp;<i v-if="!publicPage.include_image_gallery" class="fa-solid fa-eye" @click="publicPage.include_image_gallery = true" v-tooltip="'Bildgalleriet är dold i menyn. Klicka här för att göra det synligt'"></i>
                &nbsp;<i  v-else class="fa-solid fa-eye-slash" @click="publicPage.include_image_gallery = false" v-tooltip="'Dölj Bildgalleriet'"></i>
            </div>
            <div v-if="availableBookings.length == 1">
                <h3>{{ availableBookings[0].name }}</h3>                               
            </div>                 
            <div v-if="availableBookings.length > 1">              
                <h3>Boka</h3>                            
            </div>                 
            <div >
                <h3 :style="{'opacity': publicPage.include_contact ? '1' : '0.4'}">Kontakt</h3>
                &nbsp;<i v-if="!publicPage.include_contact" class="fa-solid fa-eye" @click="publicPage.include_contact = true" v-tooltip="'Kontaktfliken är dold i menyn. Klicka här för att göra den synligt'"></i>
                &nbsp;<i  v-else class="fa-solid fa-eye-slash" @click="publicPage.include_contact = false" v-tooltip="'Dölj Kontaktfliken'"></i>
                </div>                 
        </div>
    </div>

    <PopUp v-if="editNameTab" :bgColor="'rgba(250, 233, 197, 0.92)'">
        <h2 v-if="editNameTab.id != 0">Byt Namn på fliken</h2>     
        <h2 v-if="editNameTab.id == 0">Välj Namn på fliken</h2>     
        <input type="text" v-model="editNameTab.name" />
        <br><br>
        <button class="btn btn-gray float-right" @click="cancelNameOnCustomTab()">Avbryt</button>
        <button class="btn float-right" v-if="editNameTab.id != 0" @click="editNameTab = null">Klar</button>
        <button class="btn float-right" v-if="editNameTab.id == 0" @click="addTabToCustomTabs()">Klar</button>
    </PopUp>
</template>

<style scoped>
    .Menu-menu
    {
        width:100%; 
        height: 120px; 
        color: white;
        position: relative;
        z-index: 1;
    }

    .Menu-edit-menu 
    {
        position: absolute;
        font-size: 13px;
        font-weight: 600;
        left: 4px;
        top: 4px;
        text-align: center;
    }
    
    .nav{
        font-size: 14px;
        margin: auto;
        display: flex;
        justify-content: center;
        font-size: 9pt;
    }
    
    h3 {
        text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
        display: inline-block;
    }
    .nav div{
        cursor: pointer;
        text-align: center;
        vertical-align: top;
        padding: 0px 15px;
    }

    .nav i {
        color: white;
    }
    
    i:hover {
        color: white;
    }

</style>
<script>
export default {
    props: ['availableBookings', 'publicPage', 'getColor', 'url', 'selectBookingItem'],
    methods: {
        blurOut(booking)
        {
            if(this.availableBookings.filter(x => x.hovered) == 0) {
                return false;
            }
            else if(booking.hovered) {
                return false;
            }
            return true;
        },
    }
}
</script>
<template>
    <div class="BookingList-wrapper">
        <div v-for="booking in availableBookings" class="BookingList-booking-item"> 
            <div @mouseover="booking.hovered = true" @mouseout="booking.hovered = false" :class="blurOut(booking) ? 'ImageGallery-grayscale' : 'ImageGallery-no-grayscale'" >
                <div class="position-relative">
                    <div class="BookingItem-img-area">
                        <img v-if="booking.image_id" :src="url + 'uploads/'+ publicPage.customer_id+'/images/thumbnails/'+ booking.image_name" />  
                        <i v-else class="fa-regular fa-image wow-color wow-color-bg"></i>
                    </div>
                    <h4 :style="{'background-color': getColor('background_color') }">{{ booking.name }}</h4>
                </div>
                <div class="BookingItem-max-booking">
                    <p>Maxlängd på bokning: {{ booking.duration_time }} {{ booking.duration_unit }}</p>
                    <button class="Bookings-btn float-right margin-top-15" :style="{'background-color': getColor('background_color') }" @click="selectBookingItem(booking)">Boka</button>
                </div>
            </div>              
        </div>
    </div>
</template>

<style scoped>
.Bookings-btn{
        background-color: rgb(181, 205, 191);
    padding: 10px 20px;
    font-size: 21px;
    color: black;
    border: 0px solid lightgray;
    font-family: 'Raleway', sans-serif;
    -webkit-font-smoothing: antialiased;
    font-weight: 700;
    cursor: pointer;
    }
    
    .Bookings-btn:hover {
        filter: brightness(0.8);
    }

.BookingList-wrapper
    {
        display:flex;
        flex-wrap: wrap;
    }
    .BookingList-booking-item
    {
        padding:10px; 
        margin: 20px;
        background: white;
        width: calc(33% - 60px);
        box-shadow: 1px 1px #ddd;
    }

    .BookingItem-img-area
    {
        overflow: hidden;
        text-align: center;
        display: flex;
        align-items: flex-end;
    }

    .BookingItem-max-booking
    {
        margin: 30px 10px 10px 10px;
        overflow:auto;
    }

    .BookingItem-wrapper
    {
        display:flex;
        flex-wrap: wrap;

    }

    .BookingItem-img-area i
    {
        display:block;
        padding-top: 40px;
        font-size: 60px;
        background-color:aliceblue;
        height: 100vh;
    }

    img
    {
        width: 100%;
    }

    h4{
        margin-bottom:0px;
        padding:10px;
        width: 50%;
        position: absolute;
        bottom: -20px;
        left: 10px;
    }

    
.ImageGallery-grayscale {
    filter:grayscale(80%);
    transition: ease-out 0.2s;
}

.ImageGallery-no-grayscale {
    filter:grayscale(0%);
    transition: ease-out 0.2s;
}

    @media only screen and (max-width: 1000px) {
        .BookingItem-img-area {
            height: auto;
            width: auto;
        }
        
        .BookingList-booking-item
        {
            padding:10px; 
            margin: 30px;
            width: calc(100% - 80px);
        }       
    }
</style>
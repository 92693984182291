<script>
import PopUp from '@/components/PopUp.vue'
import Cropper from 'cropperjs'
import 'cropperjs/dist/cropper.css'
import config from "@/helpers/config"
import message from '@/helpers/message'
import lookups from '@/helpers/lookups'

export default {
    components: {PopUp },
    data() {
      return {
        imageSrc: null,
        cropper: null,
        uploadNewPicture: false
      };
    },
    methods: 
    {
        onFileChange(event) {
            const file = event.target.files[0];
            if (file) {
                const reader = new FileReader();
                reader.onload = (e) => {
                this.imageSrc = e.target.result;
                this.$nextTick(() => {
                    if (this.cropper) {
                    this.cropper.destroy();
                    }
                    const imageElement = this.$refs.image;
                    this.cropper = new Cropper(imageElement, {
                    aspectRatio: 1, // Kvadratisk beskärning
                    viewMode: 1,
                    });
                });
                };
                reader.readAsDataURL(file);
            }
        },
        async cropImage() 
        {          
            if (this.cropper) {
                // create canvas with cropped img
                const croppedCanvas = this.cropper.getCroppedCanvas({
                width: 300,
                height: 300,
                });

                // convert canvas to blob (file)
                croppedCanvas.toBlob(async (blob) => {
                    const formData = new FormData();
                    formData.append("file", blob, "profile_picture_" + this.user.id + ".png");

                    try {
                        const response = await fetch(config.urlToApi + 'profile_picture/', {
                            method: "POST",
                            body: formData,
                            headers: {
                                Authorization: 'Bearer ' + localStorage.samfallifyToken,
                            },
                        });
                    
                        if (!response.ok) {
                            throw new Error("Upload failed");   
                        }                
                        const result = await response.json();
                        lookups.getLookups();   
                        this.uploadNewPicture = false;
                        message.setSuccessMessage();
                       

                    } catch (error) {
                        console.error("Error uploading image:", error);
                        
                    }
                }, "image/png");
            }
        },
        closePopUp() {
            this.uploadNewPicture = false;
        },
        deleteImage()
        {
            var self = this;
            this.$communicator.post('profile_picture/delete')
                .then(function(data)
                {   
                    lookups.getLookups();                
                    message.setSuccessMessage();
                })
                .fail(function(error) {     
                    console.log(error);                   
                })  
        },     

    },
    computed:
    {
        user() {return this.$store.state.user; },   
        url() { return config.urlToServer },  
        customer() { return this.$store.state.customer; },
        profile_picture_name() { 
            var user = this.$store.state.users.find(x => x.id == this.user.id);
            return user != null ? user.profile_picture_name : null;
        }
    }
  };
  </script>
  <template>
    <div>
        <span v-if="!profile_picture_name">Du har ingen profilbild uppladdad<br></span>
        <img v-else :src="url + 'uploads/'+ customer.id +'/profile_pictures/' + profile_picture_name" /> <br>
        <button class="btn btn-gray" v-if="profile_picture_name" @click="deleteImage">Ta bort profilbilden</button>
        <button class="btn" @click="uploadNewPicture = true">Ladda upp en {{ profile_picture_name ? 'ny' : '' }} profilbild</button>
    </div>
    <PopUp :closePopup="closePopUp" v-if="uploadNewPicture">  
        <h2>Ladda upp profilbild</h2>              
        <div class="profile-picture-uploader">
            <input type="file" @change="onFileChange" accept="image/*" />
            <div v-if="imageSrc" class="crop-container">
                <div class="circle-mask">
                    <img ref="image" :src="imageSrc" alt="För beskärning" />
                </div>
            </div>
            <div class="float-right margin-top-15"> 
                <button @click="uploadNewPicture=false" class="btn btn-gray">Avbryt</button>
                <button v-if="imageSrc" @click="cropImage" class="btn">Ladda upp bilden</button>
            </div>
        </div>
    </PopUp>
  </template>
  
  <style>
  .crop-container {
  max-width: 700px;
  max-height: 700px;
  margin: 10px 0;
}
img {
  max-width: 100%;
}
</style>
export default {
    adaptHtml(html, color, backgroundColor){
      if(html == null)
      {
        return '';
      }
      html = html.replace(/<h1>/g, "<h1 style='font-size: 20px; color: "+ color+"'>");
      html = html.replace(/<h2>/g, "<h2 style='color: "+ color+"'>");
      html = html.replace(/<a href=/g, "<a class='link-in-quill-text' style='background-color: "+backgroundColor+"' href=");
      return html;
    }
}
<script>
import SelectImage from '@/views/Admin/Public/SelectImage.vue'
import ColorPicker from '@/views/Admin/Public/ColorPicker.vue'
import SvgTransition from '@/views/Admin/Public/SvgTransition.vue'
import Bookings from '@/views/Admin/Public/Bookings.vue'
import TextMenu from '@/views/Admin/Public/TextMenu.vue'
import Menu from '@/views/Admin/Public/Menu.vue'
import ContentBox from '@/views/Admin/Public/ContentBox.vue'
import Footer from '@/views/Admin/Public/Footer.vue'
import BookingItem from '@/views/Admin/Public/BookingItem.vue'
import ToAdmin from '@/components/ToAdmin.vue'
import Switch from '@/components/Form/Switch.vue'
import message from '@/helpers/message'
import config from "@/helpers/config"
import UserInstruction from '@/components/UserInstruction.vue'
export default {
    components:{ ToAdmin, Switch, UserInstruction, SelectImage, ColorPicker, Menu, TextMenu, ContentBox, Footer, BookingItem, SvgTransition, Bookings },
    created() {  
        if(this.user.user_level != 'SamfallifyCrew' && this.user.user_level != 'Redaktör')
        {
            this.$router.push('/login');
        } 

        this.$cache.createCache('accountSettings', 'account-settings/');
        this.$cache.createCache('availableBookings', 'available-bookings/');
        this.$cache.createCache('notifications', 'notifications/');
        this.$cache.createCache('boardMembers', 'board-members/');
        this.$cache.createCache('workGroups', 'work-groups/');
        this.$cache.createCache('statutes', 'statutes/');
        this.$cache.createCache('rulesOfProcedure', 'rules-of-procedure/');
        this.$cache.createCache('news', 'news/');
        this.$cache.createCache('images', 'images/');
        this.getAccountSettings();
       
    },
    data() {
        return {  
            selectedTab: 'hem', 
            publicPage: null,
            publicPageHome: null,
            publicPageCommunity: null,
            publicPageImages: null,
            publicPageColors: null,
            colors: null,
            isLoading: true,
            accountSettings: {},
            hasChanges: false,
            bookingItem: null,           
            refPublicPage: {},
            refPublicPageHome: {},
            refPublicPageCommunity: {},
            refPublicPageImages: {},
            refPublicPageColors: {},
            refCustomTabs: [],
            inAddTabMode: false,
            customTabs: [],
            selectedCustomTab: null,     
            inMenuEditMode: false,
            svgTransitionType: { name: 'triangleRight' }
        }
    }, 
    methods:
    {
        getAccountSettings()
        {
            var self = this;
            this.$communicator.get('account-settings/')
                .then(function(data)
                {
                    self.accountSettings = data;
                    if(data.enable_public_page)
                    {
                        self.getPublicPage();
                    } else
                    {
                        self.isLoading = false;
                    }
                })
                .fail(function(error) {
                    
                });
        },      
        getPublicPage()
        {
            var self = this;
            this.$communicator.get('admin-public-page/')
                .then(function(data)
                {
                    self.publicPage = data.public_page;

                    for (let prop in data.public_page) {
                        self.refPublicPage[prop] = data.public_page[prop];
                    }
               
                    self.publicPageHome = data.public_page_home;  
                    for (let prop in data.public_page_home) {
                        self.refPublicPageHome[prop] = data.public_page_home[prop];
                    }      

                    self.publicPageCommunity = data.public_page_community;
                    for (let prop in data.public_page_community) {
                        self.refPublicPageCommunity[prop] = data.public_page_community[prop];
                    }  
                    
                    self.customTabs = [];
                    self.customTabs = data.tabs;
                    self.refCustomTabs = [];
                    data.tabs.forEach(function(tab) {
                        var refTab = {};
                        for (let prop in tab) {
                            refTab[prop] = tab[prop];
                        }
                        self.refCustomTabs.push(refTab);       
                    });
                    
                    self.publicPageImages = [];
                    self.publicPageImages = data.public_page_images;
                    self.refPublicPageImages = [];
                    data.public_page_images.forEach(function(img) {
                        var refImg = {};
                        for (let prop in img) {
                            refImg[prop] = img[prop];
                        }
                        self.refPublicPageImages.push(refImg);       
                    });
                    
                    self.publicPageColors = [];
                    self.publicPageColors = data.public_page_colors;
                    self.refPublicPageColors = [];
                    data.public_page_colors.forEach(function(color) {
                        var refColor = {};
                        for (let prop in color) {
                            refColor[prop] = color[prop];
                        }
                        self.refPublicPageColors.push(refColor);       
                    });

                    if(self.selectedCustomTab != null) {

                        self.selectedCustomTab = self.customTabs.find(x => x.id == self.selectedCustomTab.id);
                    }

                    self.colors = self.publicPage.background_color;
                    self.isLoading = false;              
                })
                .fail(function(error) {
                    
                });
        },
        saveEnablePublicPage()
        {
            var self = this;
            this.$communicator.post('admin-enable-public-page/')
                .then(function(data)
                {
                    self.getAccountSettings();

                    if(data.enable_public_page)
                    {
                        message.setSuccessMessage("Grattis! Nu är er publika sida aktiverad!")
                    }
                    else {
                        message.setSuccessMessage("Er publika sida är nu inaktiverad!")
                    }

                })
                .fail(function(error) {
                    
                });
        },    
        saveAndPublishPublicPage()
        {
            var self = this;
            var data = { 
                public_page: this.publicPage,
                 public_page_community: this.publicPageCommunity, 
                 public_page_home: this.publicPageHome, 
                 available_bookings: this.availableBookings, 
                 tabs: this.customTabs, 
                 public_page_images: this.publicPageImages,  
                 public_page_colors: this.publicPageColors  
            };

            this.$communicator.post('admin-public-page/', data)
                .then(function(data)
                {
                    self.getPublicPage();
                    self.hasChanges = false;
                    self.inMenuEditMode = false;
                    message.setSuccessMessage("Toppen! Era ändringarna är nu publicerade!");
                    self.$cache.updateCache('availableBookings', 'available-bookings/');                   
                })
                .fail(function(error) {
                    
                });
        },         
        checkPropChange(newValue, referenceObject)
        {
            if(referenceObject == undefined)
            {   
                this.hasChanges = true;
            } 
            else if(newValue != null)
            {
                for (const property in newValue) {
                    if(property != 'hasChanges' && newValue[property] != referenceObject[property])
                    {                  
                        this.hasChanges = true;
                    }                   
                }
            }      
        },
        getBackgroundImage(location)
        {
            var img = this.publicPageImages.find(x => x.location == location);
            if(img == undefined)
                return '';

            return  { 
                'background-image': 'url(' + this.url + 'uploads/'+ this.customer.id+'/images/'+ img.image_name,
                'background-position': img.position
             };
        },
        getColor(location)
        {
            var color = this.publicPageColors.find(x => x.location == location);
            if(color == undefined)
                return '';

            return color.color;
        },
        selectBookingItem(bookingItem)
        {
            this.bookingItem = bookingItem;
        }
    },
    computed:
    {
        customer() { return this.$store.state.customer }, 
        user() {return this.$store.state.user; },
        availableBookings() {return this.$store.state.availableBookings == null ? [] : this.$store.state.availableBookings.filter(x => x.include_in_public_site); },
        notifications() { return this.$store.state.notifications != null ? this.$store.state.notifications.filter(x => x.include_in_public_site) : [] },
        boardMembers() { return this.$store.state.boardMembers != null ? this.$store.state.boardMembers : [] },
        workGroups() {   return this.$store.state.workGroups != undefined ? this.$store.state.workGroups : [] },
        statutes() {  return this.$store.state.statutes != null ? this.$store.state.statutes : []  },
        rulesOfProcedure() {  return this.$store.state.rulesOfProcedure != null ? this.$store.state.rulesOfProcedure : []   },  
        images() {  return this.$store.state.images != null ? this.$store.state.images : []  },  
        news() {  return this.$store.state.news != null ? this.$store.state.news : []  },  
        url() { return config.urlToServer },              
    },
    watch:
    {
        publicPage: {
            handler(newValue){  
                this.hasChanges = false;           
                this.checkPropChange(newValue, this.refPublicPage);   
            },
            deep: true
        },
        publicPageCommunity: {
            handler(newValue, oldValue){
                this.hasChanges = false;
                this.checkPropChange(newValue, this.refPublicPageCommunity);
            },
            deep: true
        },
        publicPageHome: {
            handler(newValue, oldValue){
                this.hasChanges = false;
                this.checkPropChange(newValue, this.refPublicPageHome);
            },
            deep: true
        },
        customTabs: {
            handler(newValue, oldValue){              
                this.hasChanges = false;
                for(var i = 0; i < newValue.length; i++)
                    this.checkPropChange(newValue[i], this.refCustomTabs[i]);          
            },
            deep: true
        },
        publicPageImages: {
            handler(newValue, oldValue){
                this.hasChanges = false;
                for(var i = 0; i < newValue.length; i++)
                        this.checkPropChange(newValue[i], this.refPublicPageImages[i]);       
            },
            deep: true
        },
        publicPageColors: {
            handler(newValue, oldValue){
                this.hasChanges = false;
                for(var i = 0; i < newValue.length; i++)
                        this.checkPropChange(newValue[i], this.refPublicPageColors[i]);       
            },
            deep: true
        }
    }
}
</script>
<template>
    <div v-fade-in v-if="!isLoading">
        <main class="opacity-admin-bg overflow-auto position-relative;" style="padding-bottom: 500px;">
            <div class="float-right">              
                <UserInstruction :page="this.$route.name"></UserInstruction>
            </div>
            <ToAdmin :section="'Publika Sidan'"></ToAdmin>
            <div v-if="accountSettings.enable_public_page && publicPage != null">                   
                <div style="margin-left: 460px; width: calc(100% - 460px)">                    
                    <div class="info-box overflow-auto">
                        <div class="g-80">
                            Föreningens publika sida är påslagen och nås på <a :href="'https://www.jamii.se/' + accountSettings.url_name" target="_blank"> https://www.jamii.se/{{ accountSettings.url_name }}</a>
                        </div>
                        <div class="g-20">
                            <button class="btn float-right" @click="saveEnablePublicPage">Inaktivera sidan.</button>
                        </div>
                    </div>
                    <div style="border: 1px solid black; background-color: white; position: relative">
                        <Menu :availableBookings="availableBookings" v-model:bookingItem="bookingItem" v-model:hasChanges="hasChanges" v-model:selectedCustomTab="selectedCustomTab" v-model:customTabs="customTabs" v-model:inMenuEditMode="inMenuEditMode" v-model:selectedTab="selectedTab" :publicPage="publicPage" :backgroundColor="getColor('background_color')"></Menu>                   
                        <div v-show="selectedTab == 'hem'">                           
                            <div class="top-public" style="height: 650px;" :style="getBackgroundImage('home')">
                                <ColorPicker :colors="publicPageColors" :location="'welcome_text'" :text="'Färg på välkomsttexten'" :top="'100px'"></ColorPicker>
                                <div class="AdminPublic-home-header blur-background" :style="{ 'color': getColor('welcome_text') }" style="position: absolute; top: 200px;">Välkommen till <br> {{ customer.name }}</div>                           
                                <SelectImage :publicPageImages="publicPageImages" :location="'home'"></SelectImage>  
                                <SvgTransition :publicPage="publicPage" :showMenu="true"></SvgTransition>  
                            </div>                         
                            <div class="position-relative">
                                <div style="margin:20px 90px 0px 90px; padding-bottom: 60px; overflow: auto;">   
                                    <TextMenu :model="publicPageHome" :prop="'welcome_text'" :top="'-80px'" :description="'Skriv en välkomsttext'"></TextMenu>
                                    <div v-html="this.$adaptHtml.adaptHtml(publicPageHome.welcome_text, getColor('header_color_on_white_bg'), getColor('background_color'))"></div>  
                                    <ColorPicker :colors="publicPageColors" :location="'header_color_on_white_bg'" :text="'Rubrikfärg mot vit bakgrund'" :top="'-120px'" :left="'160px'"></ColorPicker>                                                           
                                </div>
                                <SvgTransition :publicPage="publicPage" :color="getColor('background_color')"></SvgTransition>   
                            </div>
                            <div style="padding:20px 90px;position: relative" :style="{ 'background-color': getColor('background_color') }">                                
                                <ColorPicker :colors="publicPageColors" :location="'header_color'" :text="'Rubrikfärg'" :top="'-70px'" :left="'160px'"></ColorPicker>                      
                                <ColorPicker :colors="publicPageColors" :location="'background_color'" :text="'Bakgrundsfärg'" :top="'-70px'" :left="'400px'"></ColorPicker>                      
                                <h1 style="margin-top: 40px;" :style="{ 'color': getColor('header_color') }">Aktuellt</h1>  
                                <div v-for="newsItem in news.filter(x => x.include_in_public_site)" class="AdminPublic-notification-item">   
                                    <div v-html="newsItem.message"></div>
                                    <div v-for="image in newsItem.images">
                                        <img :src="url + 'uploads/'+ image.customer_id+'/images/'+ image.name"  style="width: 100%" />  
                                    </div>              
                                    <div style="margin-top:10px;">
                                        <i>/{{ newsItem.created_by_user.first_name }}
                                            {{ newsItem.created_by_user.last_name }},  
                                            <span v-date="{ date: newsItem.created_date, type: 'dateInLetters' }"></span>  
                                        </i>
                                    </div>    
                                </div>                                      
                            </div>
                        </div>
                        <div v-show="selectedTab == 'foreningen'">
                            <div class="top-public" :style="getBackgroundImage('community')">                           
                                <SelectImage :publicPageImages="publicPageImages" :location="'community'"></SelectImage> 
                                <SvgTransition :publicPage="publicPage"></SvgTransition>     
                            </div>
                            <div style="margin: 20px 90px;">
                                <TextMenu :model="publicPageCommunity" :prop="'community_text'" :top="'470px'" :description="'Skriv en beskrivande text om föreningen. Om du inte vill ha någon text kan du lämna rutan tom.'"></TextMenu>
                                <ContentBox :left="'-280px'" :top="'1123px'">
                                    <div class="AdminPublic-edit-section">
                                        <h5>Välj vilka delar av er förening som ska visas på den publika sidan</h5> 
                                        <table>
                                            <tr>
                                                <td width="60"><Switch :model="publicPageCommunity" :prop="'show_board_members'"></Switch></td>
                                                <td>Visa {{ this.$store.state.settings.BoardMember.singular }}</td>
                                            </tr>
                                            <tr>
                                                <td width="60"><Switch :model="publicPageCommunity" :prop="'show_work_groups'"></Switch></td>
                                                <td>Visa {{this.$store.state.settings.WorkGroup.plural}}</td>
                                            </tr>
                                            <tr>
                                                <td width="60"><Switch :model="publicPageCommunity" :prop="'show_statutes'"></Switch></td>
                                                <td>Visa {{this.$store.state.settings.Statute.plural}}</td>
                                            </tr>
                                            <tr>
                                                <td width="60"><Switch :model="publicPageCommunity" :prop="'show_rules_of_procedure'"></Switch></td>
                                                <td>Visa {{this.$store.state.settings.RuleOfProcedure.plural}}</td>
                                            </tr>
                                        </table>       
                                    </div>
                                </ContentBox>
                            <div v-html="this.$adaptHtml.adaptHtml(publicPageCommunity.community_text, getColor('header_color_on_white_bg'), getColor('background_color'))"></div>
                            <div class="AdminPublic-community-table" v-if="publicPageCommunity.show_board_members" v-fade-in>
                                <h2 :style="{ 'color': getColor('header_color_on_white_bg') }">{{this.$store.state.settings.BoardMember.singular}}</h2>
                                <table>
                                    <tr v-for="member in boardMembers" class="table-style">                       
                                    <td> {{member.board_member_type}}</td>
                                    <td> {{member.user_name}} ({{ member.election_period }})</td>
                                    </tr>
                                </table>
                            </div>
                            
                            <div class="AdminPublic-community-table" v-if="publicPageCommunity.show_work_groups" v-fade-in>
                                <h2 :style="{ 'color': getColor('header_color_on_white_bg') }">{{this.$store.state.settings.WorkGroup.plural}}</h2>
                                <table>
                                    <tr v-for="group in workGroups" class="table-style">                       
                                    <td> {{ group.name }}</td>
                                    <td> {{ group.members.map(x => x.user.full_name).join(", ") }}</td>
                                    </tr>
                                </table>
                            </div>
                            
                            <div class="AdminPublic-community-table" v-if="publicPageCommunity.show_statutes" v-fade-in>
                                <h2 :style="{ 'color': getColor('header_color_on_white_bg') }">{{this.$store.state.settings.Statute.plural}}</h2>                          
                                <ul style="padding-left: 20px;">
                                    <li v-for="statute in statutes" class="table-style">                       
                                        <span v-html="statute.statute"></span>                          
                                    </li>
                                </ul>
                            </div>
                            
                            <div class="AdminPublic-community-table" v-if="publicPageCommunity.show_rules_of_procedure" v-fade-in>
                                <h2 :style="{ 'color': getColor('header_color_on_white_bg') }">{{this.$store.state.settings.RuleOfProcedure.plural}}</h2>
                                <ul style="padding-left: 20px;">
                                    <li v-for="rule_of_procedure in rulesOfProcedure" class="table-style">                       
                                        <span v-html="rule_of_procedure.rule_of_procedure"></span>                          
                                    </li>
                                </ul>
                            </div>
                        </div>
                        </div>
                        <div v-show="selectedTab == 'bildgalleriet' && customer != null" :style="{ 'background-color': getColor('background_color') }">
                            <div class="top-public" :style="getBackgroundImage('image_gallery')">
                                <SelectImage :publicPageImages="publicPageImages" :location="'image_gallery'"></SelectImage>
                                <SvgTransition :publicPage="publicPage" :color="getColor('background_color')"></SvgTransition>  
                            </div>
                            <ContentBox :left="'-250px'">
                                <h5>Bildgalleriet</h5>
                                De bilder som ska visas väljs i <router-link :to="{ name: 'adminImageVault' }">bildbanken</router-link> 
                            </ContentBox>
                            <div style="margin:20px 90px 0px 90px; overflow: auto;">   
                            <h2 :style="{ 'color': getColor('header_color')}" class="padding-15">{{this.$store.state.settings.ImageGallery.singular}}</h2>
                            <div class="overflow-auto" style="padding: 10px;display: flex;flex-wrap: wrap;justify-content: center;">
                                <div v-for="image in images.filter(x => x.include_in_image_gallery)" class="AdminImageVault-img-area">              
                                    <img :src="url + 'uploads/'+ customer.id+'/images/thumbnails/'+ image.name" />               
                                </div>
                            </div>
                            </div>
                        </div>
                        <div v-show="selectedTab == 'kontakt' && customer != null">
                            <div class="top-public" :style="getBackgroundImage('contact')">
                                <SelectImage :publicPageImages="publicPageImages" :location="'contact'"></SelectImage>   
                                <SvgTransition :publicPage="publicPage"></SvgTransition>              
                            </div>
                            <div style="margin: 20px 90px; overflow: auto;">
                                <TextMenu :model="publicPage" :prop="'contact_text'" :top="'470px'" :description="'Beskriv hur ni vill bli kontaktade'"></TextMenu>
                                <h2 :style="{ 'color': getColor('header_color_on_white_bg') }" class="padding-15">Kontakt</h2>     
                                <div class="overflow-auto padding-15">                       
                                    <div v-html="this.$adaptHtml.adaptHtml(publicPage.contact_text, getColor('header_color_on_white_bg'), getColor('background_color'))"></div>            
                                </div>
                            </div>
                        </div>
                        <div v-if="selectedTab == 'customTab' && selectedCustomTab != null">
                            <div class="top-public" :style="getBackgroundImage('tab_' + selectedCustomTab.id)">   
                                <SelectImage :publicPageImages="publicPageImages" :location="'tab_' + selectedCustomTab.id"></SelectImage>
                                <SvgTransition :publicPage="publicPage"></SvgTransition> 
                            </div>
                            <div style="margin: 20px 90px; overflow: auto;">
                                <TextMenu :model="selectedCustomTab" :prop="'text'" :top="'470px'" :description="'Skriv en text om ' + selectedCustomTab.name"></TextMenu>
                                <h2 :style="{ 'color': getColor('header_color_on_white_bg') }" class="padding-15">{{selectedCustomTab.name}}</h2>     
                                <div class="overflow-auto padding-15">                       
                                    <div v-html="this.$adaptHtml.adaptHtml(selectedCustomTab.text, getColor('header_color_on_white_bg'), getColor('background_color'))"></div>            
                                </div>
                            </div>
                        </div>
                        <div v-if="selectedTab == 'bokning' && bookingItem != null">
                            <div class="top-public" :style="{ 'background-image': bookingItem.image_name != null ? 'url(' + url + 'uploads/'+ customer.id+'/images/'+ bookingItem.image_name + ')' : '' }">
                                
                            </div>
                            <BookingItem :bookingItem="bookingItem" :publicPage="publicPage" :headerColor="getColor('header_color_on_white_bg')"></BookingItem>
                        </div>
                        <div v-if="selectedTab == 'bokning' && bookingItem == null">
                            <div class="top-public" :style="{ 'background-image': availableBookings[0].image_name != null ? 'url(' + url + 'uploads/'+ customer.id+'/images/'+ availableBookings[0].image_name + ')' : '' }">                             
                                <SvgTransition :publicPage="publicPage"></SvgTransition>  
                            </div>
                            <div style="margin: 20px 90px; overflow: auto;">
                                <h2 :style="{ 'color': getColor('header_color_on_white_bg') }" class="padding-15">Bokningar</h2>                               
                                <Bookings :availableBookings="availableBookings" :url="url" :publicPage="publicPage" :getColor="getColor" :selectBookingItem="selectBookingItem"></Bookings>
                            </div>
                        </div>
                        <Footer :publicPage="publicPage" :customer="customer" :availableBookings="availableBookings" :backgroundColor="getColor('background_color')"></Footer>                                          
                    </div>
             
                </div>
                <button class="btn AdminPublic-publish-btn" v-show="hasChanges" @click="saveAndPublishPublicPage">Spara och publicera</button>
            </div>
            <div v-else>
                <div class="info-box overflow-auto">Som kund hos jamii har ni möjligheten att ha en publik sida som allmänheten kan komma åt. <br><br>
                    Adressen till föreningens publika sida kommer att vara:
                    <h3>https://www.samfallify.se/{{ accountSettings.url_name }}</h3>
                    Den publika sidan kan anpassa sig efter vad ni vill ska visas utåt och går att inaktivera när som helst. 
                    <p class="margin-top-15">
                        <button class="btn" @click="saveEnablePublicPage">Ja, vill vill gärna aktivera den publik sidan.</button>
                    </p>
                </div>
            </div>
        </main>
    </div>
</template>
<style scoped>
h1{
    font-size: 20px;
}
td{
    padding: 5px 10px 2px 0px;
}

.AdminPublic-community-table
{
    margin-bottom: 30px;
}



.AdminPublic-publish-btn
{
    padding: 15px 0px;
    background-color: #58d64d !important;
    color: white;
    position:fixed;
    bottom: 0px;
    right: 0px;
    left: 0px;
    width: 100%;
    font-size: 16px;
    z-index: 11;
}
.AdminPublic-home-header
{
    position: absolute;
    top: 300px;
    width:100%;
    text-align: center;
    font-size: 27pt;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
    font-family: "Fredoka", sans-serif;
        font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 2px;
    background-color: rgba(255,255,255,0.4);
    padding: 15px 0px;
}

.AdminPublic-divider
    {        
        width: 100%;
        height: 1px;
        margin-top: 15px;
        margin-bottom: 10px;
        filter: brightness(90%);
    }

.top-public
{
    background-image: url('@/assets/imgs/Bryggan.jpg');
    background-repeat: no-repeat;
    height: 600px;
    width: 100%;
    background-size: cover;
    background-position: center;
    margin-top: -120px;
    position: relative;
}

.table-style div
{
    display: inline-block;
    padding: 2px 10px 2px 0px;
    width:50%;
}


.AdminPublic-notification-item
    {
        padding: 15px;
        margin: 5px 20px 0px 0px;
        margin-bottom: 10px;
        display: inline-block;
        width: calc(50% - 50px);
        vertical-align: top;
        border-top: 1px solid #ddd;
    }

    .AdminPublic-notification-item i{
        font-size: 14px;
    }

/* images */
.AdminImageVault-img-area
{
    float: left;   
    color: white;
    font-size: 11px;
    position: relative;
    padding: 10px;

}

    .AdminImageVault-img-area img
    {
        cursor: pointer;
        padding: 5px;
        margin: auto;
        height: 150px;
    }
</style>